import { useEffect, useMemo, useState } from "react";
import {
  IKeySideFont,
  IKeySideLogoSize,
  IKeySideName,
  IKeyType,
} from "../store/keysTypes";
import { TEXT_CONTAINER_POSITIONS } from "../constants/textContainerPositions";
import {
  IMAGE_BACK_CANVAS_POSITION,
  IMAGE_CANVAS_POSITION,
  IMAGE_CANVAS_WIDTHS,
} from "../constants/imageCanvasProps";
import { LOGO_CANVAS_POSITIONS } from "../constants/logoCanvasPositions";
import { LOGO_CANVAS_SIZES } from "../constants/logoCanvasSizes";
import * as PIXI from "pixi.js";
import { TEXT_CONTAINER_MASK } from "../constants/textContainerMask";
import { TEXT_ELEMENT_POSITION } from "../constants/textElementPositions";

export type ITextPosition = {
  x: number;
  y: number;
};

export interface IReturnUseImage {
  getImageWidth: number;
  getImageHeight: number;
  getLogoSize: { width: number; height: number };
  getFont: string;
  getZoom?: number;
  getImageX: number;
  getTextContainerXPosition: (name: string) => number;
  getTextContainerYPosition: (name: string) => number;
  getTextXPosition: (name: string) => number;
  getTextMask: (name: string) => PIXI.Graphics;
  getLogoXPosition: number;
  getLogoYPosition: number;
}

export interface IUseImageFrameProps {
  focus: boolean;
  logoSize: IKeySideLogoSize;
  font: string;
  logo: string;
  blank: string;
  side: IKeySideName;
  type: IKeyType;
}

const useImageFrameProps = ({
  focus,
  logoSize,
  font,
  logo,
  blank,
  side,
  type,
}: IUseImageFrameProps): IReturnUseImage => {
  const [baseLogoWidth, setBaseLogoWidth] = useState<number>(0);
  const [baseLogoHeight, setBaseLogoHeight] = useState<number>(0);

  useEffect(() => {
    const newLogo = new Image();
    newLogo.onload = () => {
      setBaseLogoWidth(newLogo.width);
      setBaseLogoHeight(newLogo.height);
    };
    newLogo.src = logo;
  }, [logo]);

  const getImageHeight = useMemo(
    () => (focus || type === IKeyType.LOGO ? 800 : 400),
    [focus, type]
  );

  const getImageWidth = useMemo(() => {
    if (focus || type === IKeyType.LOGO) {
      return IMAGE_CANVAS_WIDTHS[blank] * 2;
    }
    return IMAGE_CANVAS_WIDTHS[blank];
  }, [focus, blank, type]);

  const getImageX = useMemo(() => {
    if (side === IKeySideName.BACK) {
      return IMAGE_BACK_CANVAS_POSITION[blank][
        focus || type === IKeyType.LOGO ? "zoom" : "default"
      ];
    }
    return IMAGE_CANVAS_POSITION[blank][
      focus || type === IKeyType.LOGO ? "zoom" : "default"
    ];
  }, [focus, getImageWidth, type]);

  const getLogoSize = useMemo(() => {
    const { width, height } = LOGO_CANVAS_SIZES[blank][logoSize];

    const heightDiff = width * (baseLogoHeight / baseLogoWidth);

    if (heightDiff > height) {
      return {
        width: height * (baseLogoWidth / baseLogoHeight),
        height,
      };
    }

    return {
      width,
      height: width * (baseLogoHeight / baseLogoWidth),
    };
  }, [blank, logoSize, baseLogoWidth, baseLogoHeight]);

  const getLogoXPosition = useMemo(() => {
    const logoPosition = LOGO_CANVAS_POSITIONS[blank];

    if (side === IKeySideName.BACK && logoPosition?.backX) {
      return logoPosition?.backX;
    }
    return logoPosition.x;
  }, [blank]);

  const getLogoYPosition = useMemo(() => {
    const logoPosition = LOGO_CANVAS_POSITIONS[blank];

    if (side === IKeySideName.BACK && logoPosition?.backY) {
      return logoPosition?.backY;
    }
    return LOGO_CANVAS_POSITIONS[blank].y;
  }, [blank, side]);

  const getFont = useMemo(() => {
    if (font === IKeySideFont.ARIAL) {
      return "Arial, sans-serif";
    }

    return "Times New Roman, sans-serif";
  }, [font]);

  const getTextContainerXPosition = useMemo(() => {
    return (name: string) => {
      if (side === IKeySideName.BACK) {
        return (
          TEXT_CONTAINER_POSITIONS[blank][
            focus || type === IKeyType.LOGO ? "zoomBack" : "defaultBack"
          ]?.[name].x ||
          TEXT_CONTAINER_POSITIONS[blank][focus ? "zoom" : "default"][name].x
        );
      }
      return TEXT_CONTAINER_POSITIONS[blank][
        focus || type === IKeyType.LOGO ? "zoom" : "default"
      ][name].x;
    };
  }, [focus, blank, side, type]);

  const getTextContainerYPosition = useMemo(() => {
    return (name: string) => {
      return TEXT_CONTAINER_POSITIONS[blank][focus ? "zoom" : "default"][name]
        .y;
    };
  }, [focus, blank]);

  const getTextXPosition = useMemo(() => {
    return (name: string) => {
      const blankTextPosition = TEXT_ELEMENT_POSITION[blank];

      return side === IKeySideName.BACK
        ? blankTextPosition[
            focus || type === IKeyType.LOGO ? "zoomBack" : "defaultBack"
          ]?.[name] || blankTextPosition[focus ? "zoom" : "default"][name]
        : blankTextPosition[focus ? "zoom" : "default"][name];
    };
  }, [focus, blank]);

  const getTextMask = useMemo(() => {
    return (name: string) => {
      const blankMask = TEXT_CONTAINER_MASK[blank];
      const x =
        side === IKeySideName.BACK
          ? blankMask[
              focus || type === IKeyType.LOGO ? "zoomBack" : "defaultBack"
            ]?.[name].x || blankMask[focus ? "zoom" : "default"][name].x
          : blankMask[focus ? "zoom" : "default"][name].x;
      const rectWidth =
        side === IKeySideName.BACK
          ? blankMask[
              focus || type === IKeyType.LOGO ? "zoomBack" : "defaultBack"
            ]?.[name].rectWidth ||
            blankMask[focus ? "zoom" : "default"][name].rectWidth
          : blankMask[focus ? "zoom" : "default"][name].rectWidth;

      return new PIXI.Graphics()
        .beginFill(0x32ffff)
        .drawRect(x, 0, rectWidth, 400)
        .endFill();
    };
  }, [focus, blank]);

  return {
    getLogoSize,
    getFont,
    getImageHeight,
    getImageWidth,
    getImageX,
    getLogoXPosition,
    getLogoYPosition,
    getTextContainerXPosition,
    getTextContainerYPosition,
    getTextXPosition,
    getTextMask,
  };
};

export default useImageFrameProps;
